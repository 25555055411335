<template>
  <div class="page">
    <div class="top">
      <!--  顶部-->
      <!--      <div class="reply" v-if="dataList.fowardText">-->
      <!--        <div class="content">-->
      <!--          <div class="title">-->
      <!--            <div class="tip">推荐转发文案:</div>-->
      <!--            <div class="copy" @click="copyText(dataList.fowardText)">复制</div>-->
      <!--          </div>-->
      <!--          <div class="text">{{ dataList.fowardText }}</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="container">
        <div class="content">
          <div class="poster-area">
              <img :src="workFissionInfoData.welcome.full_link_cover_url" alt="" v-if="workFissionInfoData.welcome.full_link_cover_url"
                   :style="{height: '80px',width: '80px',borderRadius:'6px'}">
            <div style="margin-left: 10px;font-size: 16px;font-weight: bold" class="line2" v-if="workFissionInfoData.welcome.link_title">
              {{ workFissionInfoData.welcome.link_title }}
            </div>
          </div>
          <div class="title">推荐给朋友
          </div>
          <div class="time-text">
            还剩
            <span class="time-count">{{ countDown.day || 0 }}</span><span style="margin-right: 3px;">天</span>
            <span class="time-count">{{ countDown.hour || 0 }}</span><span style="margin-right: 3px;">时</span>
            <span class="time-count">{{ countDown.min || 0 }}</span><span style="margin-right: 3px;">分</span>
            <span class="time-count">{{ countDown.sec || 0 }}</span><span>秒</span>后结束
          </div>
          <div style="width: 100%;text-align: center">
            <a-button class="share-btn" v-if="workFissionInfoData.fission.status === '已结束'" shape="round" @click="endedMaskFlag = true">分享海报给好友</a-button>
            <a-button class="share-btn" v-else shape="round" @click="resultMaskFlag = true">分享海报给好友</a-button>
          </div>
        </div>
      </div>
      <!--      <div class="tips">-->
      <!--        <div class="tip">-->
      <!--          <span class="text">长按保存专属海报分享给好友</span>-->
      <!--        </div>-->
      <!--        <div class="tip">-->
      <!--          <span class="text long-text">好友扫码添加微信后即助力成功</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="code-image">-->
      <!--        <div class="code-border">-->
      <!--          &lt;!&ndash;  个人海报  &ndash;&gt;-->
      <!--          <div v-show="posterType == 0">-->
      <!--            <div id="code-template" ref="template" v-show="!posterImage">-->
      <!--            </div>-->
      <!--            <img :src="posterImage" alt="" v-if="posterImage" :style="{height: bgHeight,width: '100%'}">-->
      <!--          </div>-->
      <!--          &lt;!&ndash;          个人名片&ndash;&gt;-->
      <!--          <div id="code-template-simple" ref="template_simple" v-if="posterType== 1">-->
      <!--            <div class="info">-->
      <!--              <div class="left">-->
      <!--                <img class="logo" :src="dataList.cardCorpLogo">-->
      <!--              </div>-->
      <!--              <div class="right">-->
      <!--                <div class="title">{{ dataList.cardCorpImageName }}</div>-->
      <!--                <div class="name">{{ dataList.cardCorpName }}</div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="code">-->
      <!--              <img class="qrcode" :src="dataList.qrcodeUrl">-->
      <!--              <img class="logo" :src="dataList.cardCorpLogo">-->
      <!--            </div>-->
      <!--            <div class="tip">扫一扫上面的二维码图案</div>-->
      <!--            <div class="tip">加我企业微信</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="bottom-info">
        <div class="flex">
          <div :class="selected == 1?'select':'no-select1'" @click="selected = 1">活动奖品</div>
          <div :class="selected == 2?'select':'no-select2'" @click="selected = 2">推荐记录</div>
        </div>
        <div style="height: auto;margin-top: -40px;background: #FFFFFF;border-radius: 6px">
          <div style="padding: 40px 10px 20px 10px" v-if="selected == 1">
            <div class="content" v-if="dataLists.task && dataLists.task.length > 0">
              <div class="row" v-for="(item, index) in dataLists.task" :key="index">
                <div class="left">
                  <img :src="'https://api.chinainnet.com/static/'+item.prizeImg" class="avatar">
                </div>
                <div class="right">
                  <div class="user-info">
                    <div class="name">{{ item.prizeName }}</div>
                    <div class="process">
                      <div style="width: 70px">
                        <a-progress :percent="item.prizeReceiveRatio" strokeColor="#00D294" :showInfo="false"/>
                      </div>
                      <div style="color: #00D294;font-size: 12px;margin-left: 10px">剩余<span style="font-weight: bold">{{item.prizeReceiveRatio}}</span>%</div>
                    </div>
                  </div>
                  <div class="tips">
                    <div class="right-btn no-active"
                         v-if="item.receive_status == 0 && item.count > dataLists.invite_count"
                         @click="openNoResizeMaskFlag(item.count)">去领取
                    </div>
                    <div class="right-btn active"
                         v-if="item.receive_status == 0 && item.count <= dataLists.invite_count"
                         @click="openGiftMask(item, index)">去领取
                    </div>
                    <div class="right-btn actived" v-if="item.receive_status">已领取</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="text-align: center;margin-top: 20px">没有更多了</div>
          </div>
          <div style="padding: 40px 10px 20px 10px" v-if="selected == 2">
            <div class="content" v-if="friendList.length > 0">
              <div class="row" v-for="(item, index) in friendList" :key="index">
                <div class="left">
                  <img :src="item.avatar" class="avatar">
                </div>
                <div class="right">
                  <div class="user-info">
                    <div class="name">{{ item.nickname }}</div>
                    <div class="time">{{ item.createdAt }}</div>
                  </div>
                  <div class="tips">
                    <div class="tip" v-if="item.fail" @click="failMaskFlag = true">助力失败</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="text-align: center;margin-top: 20px">没有更多了</div>
          </div>
        </div>
      </div>
    </div>


    <!--    <div class="bottom-box">-->
    <!--      <div class="long-btn"-->
    <!--           @click="$router.push('/workFission/speed?id='+fissionId+'&unionId='+wxUserData.unionid)">-->
    <!--        查看助力进度-->
    <!--      </div>-->
    <!--    </div>-->
    <input type="text" ref="copyInput" style="position: fixed; opacity: 0">

    <canvas ref="canvas" width="720" height="1280">
      您的浏览器不支持canvas
    </canvas>

    <div class="mask" v-if="giftMaskFlag" @click="giftMaskFlag = false">
      <div class="content" @click.stop>
        <div class="title">恭喜你完成任务</div>
        <img src="../../static/images/gift-image.png" alt="" class="gift-image">
        <div class="tip">点击下方按钮领取任务奖励</div>
        <div class="get-gift" @click="getGift">前往领取</div>
      </div>
    </div>

    <div class="code-mask" v-if="codeMaskFlag" @click="codeMaskFlag = false">
      <div class="content" @click.stop>
        <div class="title">领取奖品</div>
        <div class="tip">恭喜你完成任务，快添加微信领取奖品吧！</div>
        <img :src="codeUrl" alt="" class="code-image">
        <div class="bottom-tip">长按识别二维码添加微信</div>
      </div>
    </div>

    <div class="fail-mask" v-if="failMaskFlag" @click="failMaskFlag = false">
      <div class="content" @click.stop>
        <div class="title">助力失败</div>
        <img src="../../static/images/error.png" alt="" class="fail-image">
        <div class="tip">该客户已删除员工好友，助力失败</div>
        <div class="fail-btn" @click="failMaskFlag = false">我知道了</div>
      </div>
    </div>

    <div class="fail-mask" v-if="noResizeMaskFlag" @click="noResizeMaskFlag = false">
      <div class="content" @click.stop>
        <div class="title">成功邀请{{ count }}个好友，即可领取</div>
        <div class="fail-btn" @click="noResizeMaskFlag = false">我知道了</div>
      </div>
    </div>

    <div class="fail-mask" v-if="endedMaskFlag" @click="endedMaskFlag = false">
      <div class="content" @click.stop>
        <div class="title">活动已结束</div>
        <div class="fail-btn" @click="endedMaskFlag = false">我知道了</div>
      </div>
    </div>

    <div class="result-mask" v-if="resultMaskFlag" @click="resultMaskFlag = false">
      <div class="content" style="position: relative" @click.stop>
        <a-icon type="close-circle"
                style="position: absolute;margin-top: -60px;right: 10px;font-size: 30px;color: #FFFFFF"
                @click="resultMaskFlag = false"/>
        <div class="title line2">{{ dataList.fowardText }}</div>
        <a-button type="primary" ghost style="margin-bottom: 20px;" v-if="dataList.fowardText" @click="copyText(dataList.fowardText)">复制文案
        </a-button>
        <div class="poster-area">
          <div>
            <div v-if="posterType == 0">
              <img :src="posterImage" alt="" v-if="posterImage" :style="{height: 'auto',width: '200px'}">
            </div>
            <div v-if="posterType == 1">
              <img :src="dataList.qrcodeUrl" alt="" v-if="dataList.qrcodeUrl" :style="{height: '200px',width: '200px'}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {posterApi, openUserInfoApi} from "@/api/workFission";
import {inviteFriendsApi, receiveApi, taskDataApi, workFissionInfo} from "../../api/workFission";

export default {
  name: "index",
  data() {
    return {
      url: window.location.href,
      //海报图片
      posterImage: '',
      selected: 1,
      dataList: {},
      workFissionInfoData:{},
      isEnd: false,
      giftMaskFlag: false,
      giftInfo: {},
      codeMaskFlag: false,
      codeUrl: '',
      failMaskFlag: false,
      resultMaskFlag: false,
      noResizeMaskFlag: false,
      endedMaskFlag: false,
      count: 0,
      dataLists: {
      },
      friendList: [],
      countDown: {
        day: '-',
        hour: '-',
        min: '-',
        sec: '-',
      },
      timer: 0,
      userInfo: {},
      bgHeight: 'auto',
      base64Img: '',
      //  微信用户数据
      wxUserData: {},
      //  页面类型
      posterType: 0,
      fissionId: 0
    }
  },
  created() {
    this.fissionId = this.$route.query.id;
    this.getOpenUserInfo();
    this.workFissionInfoApi();
  },
  methods: {
    workFissionInfoApi(){
      workFissionInfo({
        id: this.fissionId
      }).then((res) => {
         this.workFissionInfoData = res.data;
      })
    },
    openNoResizeMaskFlag(param) {
      this.noResizeMaskFlag = true;
      this.count = param;
    },
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/workFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;
        this.getPosterData();

      });
    },
    getDataList() {
      //获取任务信息
      taskDataApi({
        unionId: this.wxUserData.unionid,
        fissionId: this.fissionId
      }).then(res => {
        this.dataLists = res.data;
      })
      //获取邀请的好友列表
      inviteFriendsApi({
        unionId: this.wxUserData.unionid,
        fissionId: this.fissionId
      }).then(res => {
        // console.log(res);
        this.friendList = res.data;
      })
      this.setCountDown();
    },
    getGift() {
      receiveApi({
        unionId: this.wxUserData.unionid,
        fissionId: this.fissionId,
        level: this.giftInfo.level
      }).then(res => {
        this.getDataList();
      })

      if (this.giftInfo.gift_type === 1) {
        window.open(this.giftInfo.gift_url);
      }
      if (this.giftInfo.gift_type === 0) {
        this.giftMaskFlag = false;
        this.codeUrl = this.giftInfo.gift_url;
        this.codeMaskFlag = true;
      }
    },
    openGiftMask(item, index) {
      if (this.dataLists.invite_count < item.count) return;
      this.giftMaskFlag = true;

      let clone = JSON.parse(JSON.stringify(item));
      clone.level = index + 1;
      this.giftInfo = clone;
    },
    setCountDown() {
      clearInterval(this.timer);

      //先检测一次
      let tempNow = Math.floor(new Date().getTime() / 1000);
      let tempGap = this.dataLists.end_time - tempNow;
      // console.log('剩余时间', tempGap)
      if (tempGap <= 0) {
        this.isEnd = true;
        return;
      }
      this.setTime(tempGap);

      this.timer = setInterval(() => {
        let now = Math.floor(new Date().getTime() / 1000);
        let gap = this.dataLists.end_time - now;
        if (gap <= 0) {
          this.isEnd = true;
          clearInterval(this.Timer);
          return;
        }
        this.setTime(gap);
      }, 1000);
    },
    setTime(time) {
      this.countDown.day = Math.floor(time / 60 / 60 / 24);
      this.countDown.hour = Math.floor(time / 60 / 60 % 24);
      this.countDown.min = Math.floor(time / 60 % 60);
      this.countDown.sec = Math.floor(time % 60);
    },
    //获取页面海报信息
    getPosterData() {
      let params = {
        fissionId: this.fissionId,
        unionId: this.wxUserData.unionid,
        openId: this.wxUserData.openid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl
      }
      posterApi(params).then((res) => {
        this.posterType = res.data.posterType
        this.dataList = res.data

        this.createPoster()
        this.getDataList();
      })
    },
    async createPoster() {
      const ctx = this.$refs.canvas.getContext('2d')

      const w = 720
      const h = 1280

      const bg = await this.getImg(this.dataList.coverPic)
      const qr = await this.getImg(this.dataList.qrcodeUrl)

      ctx.drawImage(bg, 0, 0, w, h)
      if (this.dataList.qrcodeX) {
        // ctx.drawImage(qr, this.dataList.qrcodeX * 3.37, this.dataList.qrcodeY* 3.23,  this.dataList.qrcodeW * 3,  this.dataList.qrcodeH * 3)
        ctx.drawImage(qr, this.dataList.qrcodeX * 3.24, this.dataList.qrcodeY * 3.23, this.dataList.qrcodeW * 3.24, this.dataList.qrcodeH * 3.24)
      } else {
        ctx.drawImage(qr, 550, 1100, 150, 150)
      }


      if (this.wxUserData.nickname && this.dataList.nicknameShow == 1) {
        ctx.font = '30px Verdana'
        ctx.fillStyle = this.dataList.nicknameColor ?? '#fff'
        if (this.dataList.nicknameX) {
          ctx.fillText(this.wxUserData.nickname, this.dataList.nicknameX * 3.24, this.dataList.nicknameY * 3.23 + (this.dataList.avatarW * 3.23 / 2))
        } else {
          ctx.fillText(this.wxUserData.nickname, 115, 74)
        }
      }

      if (this.wxUserData.headimgurl && this.dataList.avatarShow == 1) {
        const avatar = await this.getImg(this.wxUserData.headimgurl)
        // ctx.drawImage(avatar, 30, 30, 70, 70)
        if (this.dataList.avatarX) {
          ctx.drawImage(avatar, this.dataList.avatarX * 3.24, this.dataList.avatarY * 3.23, this.dataList.avatarW * 3.23, this.dataList.avatarH * 3.23)
        } else {
          ctx.drawImage(avatar, 30, 30, 70, 70)
        }
      }

      this.posterImage = this.$refs.canvas.toDataURL('image/png')
    },
    copyText(text) {
      const inputElement = this.$refs.copyInput;
      inputElement.value = text;
      inputElement.select();
      document.execCommand('Copy');
      this.$message.success('复制成功')
    },
    getImg(url) {
      url += '?rand=' + Math.random()

      return new Promise(resolve => {
        const img = new Image()

        img.src = url
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          resolve(img)
        }

        img.onerror = err => {
          console.log('图片加载失败');
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.line1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.line3 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden
}


/deep/ .ant-progress-text {
  color: #00D294;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 720px;
  height: 1280px;
  z-index: 10000;
  display: none;
}

.page {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #1A77FF, #6EA9FF);
  padding: 0 15px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //background-image: url("../../static/images/bg.png");
  background-size: cover;
  overflow-y: auto;

  .top {
    .reply {
      padding-top: 16px;

      .content {
        padding: 16px 20px;
        border-radius: 16px;
        background-color: rgb(253, 253, 229);

        .title {
          display: flex;
          justify-content: space-between;

          .tip {
            color: saddlebrown;
            font-size: 15px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 7px;
              background-color: #b97e1229;
            }
          }

          .copy {

          }
        }

        .text {

        }
      }
    }

    .container {
      padding-top: 15px;

      .content {
        padding: 16px 10px;
        border-radius: 6px;
        background-color: #FFFFFF;

        .poster-area {
          width: 100%;
          padding: 11px 10px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          background: #F0F9FF;
          box-shadow: 0px 1px 3px 0px rgba(110,169,255,0.15);
        }

        .title {
          display: flex;
          justify-content: center;
          font-size: 18px;
          margin: 20px 0;
          font-weight: 600;
          color: #ca3d46;
        }

        .time-text {
          font-size: 14px;
          font-weight: 500;
          color: #000000;
          text-align: center;
          margin-bottom: 20px;

          .time-count {
            padding: 2px 3px;
            background: #000000;
            color: #FFFFFF;
            margin-right: 3px;
          }
        }

        .share-btn {
          background: linear-gradient(45deg, #FF7C34, #FFA200);
          width: 240px;
          height: 50px;
          border-radius: 30px;
          color: #FFFFFF;
          font-size: 15px;
          font-weight: 600;
          border: none;
          margin: 0 auto;
        }
      }
    }

    .tips {
      padding: 20px 0;

      .tip {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        .text {
          position: relative;
          color: white;
          z-index: 0;

          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            z-index: -1;

            left: ((100% - 90%) / 2);

            height: 10px;
            width: 90%;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        .long-text {
          &::before {
            left: ((100% - 110%) / 2);
            width: 110%;
          }
        }
      }
    }

    .code-image {
      width: 100%;
      background-color: navajowhite;
      border-radius: 10px;
      box-shadow: inset 0 4px 6px 0 #0000001c;
      border: 2px solid white;
      padding: 10px;

      .code-border {
        width: 100%;
        padding: 16px;
        background-color: white;
        border-radius: 12px;

        #code-template {
          position: relative;

          .bg {
            width: 100%;
          }

          .user-info {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            align-items: center;

            .avatar {
              margin-right: 10px;
              width: 36px;
              height: 36px;
              border-radius: 50%;
            }

            .name {
              font-size: 14px;
            }


          }

          .qrcode {
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 0;
            right: 0;
          }
        }

        #code-template-simple {
          border: 1px solid gray;
          padding: 20px;

          .info {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .left {
              margin-right: 10px;

              .logo {
                width: 34px;
                height: 34px;
              }
            }

            .right {
              .title {
                font-size: 14px;
                color: #000000;
              }

              .name {
                font-size: 12px;
              }
            }
          }

          .code {
            width: 84%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 0 auto 20px;

            .qrcode {
              width: 100%;
              height: 100%;
            }

            .logo {
              border: 1px solid white;
              position: absolute;
              width: 24%;
              height: 24%;
            }
          }

          .tip {
            text-align: center;
          }
        }
      }
    }
  }


  .process {
    display: flex;
    align-items: center;
  }

  .bottom-info {
    height: auto;
    margin-top: 15px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      align-items: end;
    }

    .select {
      border-radius: 6px 6px 0 0;
      background: #FFFFFF;
      font-size: 14px;
      color: #000000;
      font-weight: bold;
      width: 50%;
      text-align: center;
      height: 40px;
      line-height: 40px;
    }

    .no-select1 {
      border-radius: 6px 0 6px 0;
      background: rgba(37, 125, 254, .15);
      font-size: 14px;
      color: #666666;
      font-weight: 500;
      text-align: center;
      width: 50%;
      height: 40px;
      line-height: 40px;
    }

    .no-select2 {
      border-radius: 0 6px 0 6px;
      background: rgba(37, 125, 254, .15);
      font-size: 14px;
      color: #666666;
      font-weight: 500;
      text-align: center;
      width: 50%;
      height: 40px;
      line-height: 40px;
    }

    .content {
      height: auto;
      flex-grow: 1;
      overflow-y: auto;

      .row {
        padding: 14px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;

        .left {
          margin-right: 14px;

          .avatar {
            width: 44px;
            height: 44px;
            border-radius: 50%;
            border: 2px solid #ffaf45;
          }
        }

        .right {
          flex-grow: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .user-info {
            .name {
              color: saddlebrown;
              font-size: 16px;
              font-weight: bold;
            }

            .time {
              color: #bd6e3a;
              font-size: 12px;
            }
          }

          .right-btn {
            width: 70px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 15px;
            font-size: 15px;
            color: #FFFFFF;
          }

          .actived {
            background: linear-gradient(45deg, #F87501, #FDBB28);
          }

          .active {
            background: linear-gradient(45deg, #4076FF, #6D95FD);
          }

          .no-active {
            background: linear-gradient(45deg, #C4C2C7, #BAB9BB);
          }

          .tips {
            .tip {
              font-size: 12px;
              color: #bd6e3a;
            }
          }
        }
      }
    }

  }

  .bottom-box {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px 0 15px;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ff5636;
    display: flex;
    justify-content: center;

    .long-btn {
      width: 90%;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: darkorange;
      font-size: 16px;
      border: 2px solid #ffd6a1;
      border-radius: 100px;
      background-color: #ffecdb;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 84%;
      padding: 20px 0 30px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 16px;
        color: black;
      }

      .gift-image {
        width: 70%;
      }

      .tip {
        margin-top: -15%;
        margin-bottom: 10px;
      }

      .get-gift {
        background-color: #ff5636;
        width: 50%;
        height: 32px;
        display: flex;
        color: white;
        border-radius: 32px;
        justify-content: center;
        align-items: center;

      }
    }
  }

  .code-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 84%;
      padding: 20px 0 30px;
      background-color: white;
      display: flex;
      border-radius: 6px;
      flex-direction: column;
      align-items: center;

      .title {
        color: black;
        margin-bottom: 10px;
        font-size: 16px;
      }

      .tip {
        color: saddlebrown;
        font-size: 13px;
      }

      .code-image {
        width: 70%;
        margin-bottom: 20px;
      }

      .bottom-tip {
        font-size: 15px;
      }
    }
  }

  .fail-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    display: flex;
    justify-content: center;
    align-items: center;

    .content {
      width: 84%;
      padding: 20px 0 30px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 17px;
        color: black;
        margin-bottom: 20px;
      }

      .fail-image {
        width: 34%;
        margin-bottom: 12px;
      }

      .tip {
        margin-bottom: 20px;
      }

      .fail-btn {
        background-color: #ff5636;
        width: 70%;
        height: 40px;
        font-size: 15px;
        display: flex;
        color: white;
        border-radius: 32px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .result-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    //display: flex;
    //justify-content: center;
    //align-items: center;
    padding-top: 200px;

    .content {
      width: 84%;
      padding: 20px 10px 30px;
      background-color: white;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      .title {
        font-size: 14px;
        color: black;
        margin-bottom: 20px;
      }


      .poster-area {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 16px;
        background: #eeecec;

      }

    }
  }

}

.line2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
</style>
